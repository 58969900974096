<template>
  <div>
    <div class="text-left" style="margin-left: auto">
        <div>{{ $t("admin.welcomeToAdmin") }}</div>
    </div>
    <div class="top-panel text-left">
      <div class="number-column">
        <label class="big-num">{{$apollo.queries.filteredCampaigns.loading ? "..." : filteredCampaigns.length}}</label><img alt="zelené brýle" src="../../assets/svg/admin-homepage-interest-green.svg" class="glasses-green"><br/>
        <label class="grey-label">{{ $tc("admin.currentlyActiveCampaigns", filteredCampaigns ? filteredCampaigns.length : 0)}}</label><br/>
        <router-link to='/admin/campaigns'>{{ $t("admin.allCampaigns") }}</router-link>
      </div>
      <div class="number-column">
        <label class="big-num">{{$apollo.queries.filteredTravelTips.loading ? "..." : filteredTravelTips.length}}</label><img alt="zelené brýle" src="../../assets/svg/admin-homepage-interest-green.svg" class="glasses-green"><br/>
        <label class="grey-label">{{ $tc("admin.currentlyActiveTravelTips", filteredTravelTips ? filteredTravelTips.length : 0) }}</label><br/>
        <router-link to='/admin/travelTips'>{{ $t("admin.allTravelTips") }}</router-link>
      </div>
      <div class="number-column">
        <label class="big-num">{{$apollo.queries.filteredUsers.loading ? "..." : filteredUsers.length}}</label><br/>
        <label class="grey-label">{{ $tc("admin.registeredUsers", filteredUsers ? filteredUsers.length : 0) }}</label><br/>
        <router-link to='/admin/users'>{{ $t("admin.manageUsers") }}</router-link>
      </div>
    </div>
    <div style="margin-top: 60px; position: relative">
      <div style="font-size: 50px; position: absolute; left: 50%; top: 30%; transform: translate(-50%, -50%); z-index: 10; opacity: 0.05; pointer-events: none;">DUMMY GRAPH!</div>
      <div class="text-left">{{ $t("admin.trafficOfTrekioInTheLast") }}</div>
      <div class="d-flex" style="gap: 20px; margin-top: 22px;">
        <div @click="changeTimeRange('DAY')" class="range-option" :class="{'highlighted': chosenRange == 'DAY'}">24h</div>
        <div @click="changeTimeRange('WEEK')" class="range-option" :class="{'highlighted': chosenRange == 'WEEK'}">{{ $t("general.week")}}</div>
        <div @click="changeTimeRange('MONTH')" class="range-option" :class="{'highlighted': chosenRange == 'MONTH'}">{{ $t("general.month")}}</div>
        <div @click="changeTimeRange('YEAR')" class="range-option" :class="{'highlighted': chosenRange == 'YEAR'}">{{ $t("general.year")}}</div>
      </div>
      <div style="height: 400px">
        <vue-highcharts :options="options" ref="lineCharts"></vue-highcharts>
      </div>
    </div>
  </div>
</template>

<script>
    import { ACTIVE_CAMPAIGNS } from "@/api/graphql/query/CampaignQuery";
    import { ACTIVE_TRAVEL_TIPS } from "@/api/graphql/query/TravelTipQuery";
    import { FILTERED_USERS } from "@/api/graphql/query/UserQuery";
    import VueHighcharts from "vue2-highcharts";


    // const keys = require('./../../google_key_oauth.json');

    export default {
        name: "AdminHomePage",

      async created() {
        if (localStorage.getItem('username')) {
          await this.$store.commit('setCurrentUserId', localStorage.getItem('id'));
          await this.$store.commit('setCurrentUser', localStorage.getItem('username'));
          await this.$store.commit('setCurrentUserRole', localStorage.getItem('role'));
        }
        this.setup()
      },

      components: {
        VueHighcharts
      },

      data: function() {
        return {
          username: null,
          numberOfActiveCampaigns: 0,
          numberOfActiveTravelTips: 0,
          numberOfUsers: 0,

          chosenRange: 'DAY',

          options: {
            series: [{
              name: 'Návštěvnost',
              data: [...Array(25)].map(()=>{return Math.floor(Math.random()*10)})
            }],
            plotOptions: {
              series: {
                pointPlacement: 'on',
                states: {
                  hover: {
                    lineWidth: 1
                  }
                }
              },
              spline: {
                lineWidth: 1,
                marker: {
                  enabled: false
                }
              }
            },
            points: {
              enabled: false,
              fillColor: '#222',
            },
            chart: {
              type: 'spline'
            },
            colors: ['#3BEA7E'],
            legend: {
              enabled: undefined
            },
            title: {
              text: undefined
            },
            xAxis: {
              tickmarkPlacement: 'on',
              alignTicks: false,
              gridLineWidth: 0,
              crosshair: {
                width: 2,
                color: '#EAEDEF'
              },
              title: {
                text: undefined
              },
              labels: {
                step: 2,
                style :{
                  fontSize: '16px',
                  color: '#A1ABB3'
                },
                enabled: true
              },
              categories: [...Array.from({length: 24}, (x, i) => i + ':00'), '0:00']
            },
            yAxis: {
              gridLineWidth: 0,
              title: {
                text: undefined
              },

              labels: {
                enabled: false
              }
            },
            tooltip: {
              formatter: function () {
                return this.y + ' pw'
              },
              style:{
                color: '#fff',
                cursor: 'default',
                fontSize: '12px',
              },
              crosshairs: true,
              shared: true,
              color: '#fff',
              backgroundColor: '#222',
              borderRadius: 0,
              borderWidth: 0
            },
            credits: {
              enabled: false
            },
          },
        }
      },

      methods: {
        changeTimeRange(timeRange) {
          this.chosenRange = timeRange
          this.options.xAxis.labels.step = 1
          if (timeRange == 'DAY') {
            this.options.xAxis.labels.step = 2
            this.options.series[0].data = [...Array(25)].map(()=>{return Math.floor(Math.random()*100)})
            this.options.xAxis.categories = [...Array.from({length: 24}, (x, i) => i + ':00'), '0:00']
          }
          if (timeRange == 'WEEK') {
            this.options.series[0].data = [...Array(7)].map(()=>{return Math.floor(Math.random()*1000)})
            this.options.xAxis.categories = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          }
          if (timeRange == 'MONTH') {
            this.options.series[0].data = [...Array(31)].map(()=>{return Math.floor(Math.random()*1000)})
            this.options.xAxis.categories = Array.from({length: 31}, (x, i) => i + 1);
          }
          if (timeRange == 'YEAR') {
            this.options.series[0].data = [...Array(12)].map(()=>{return Math.floor(Math.random()*20000)})
            this.options.xAxis.categories = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
          }
          this.$refs.lineCharts.delegateMethod('update', this.options, true)
        },
        setup(){
          this.username = localStorage.getItem("username")
        },

        // TODO: předělat na oAuth je doporučený googlem a jeho key json jsem ti sem dal
        // async google() {
        //   const client = new JWT({
        //     email: keys.client_email,
        //     key: keys.private_key,
        //     scopes: ['https://www.googleapis.com/auth/cloud-platform'],
        //   });
        //   const url = `https://analyticsreporting.googleapis.com/v4/projects/${keys.project_id}`;
        //   const res = await client.request({url});
        //   console.log(res.data);
        // }
      },    

      apollo: {
        filteredCampaigns: {
          query: ACTIVE_CAMPAIGNS,
          variables() {
            return {
              filter: {
                state: "PUBLISHED",
              }
            } 
          },
        },
        filteredTravelTips: {
          query: ACTIVE_TRAVEL_TIPS,
          variables() {
            return {
              filter: {
                  state: "PUBLISHED",
                }   
            }
          }
        },
        filteredUsers: {
          query: FILTERED_USERS
        },
      },
    }
</script>

<style scoped lang="scss">
    @import '@/scss/variables';

a, a:hover {
  color: $tr-green;
}

.top-panel {
  border-top: 3px dashed $tr-light-gray;
  border-bottom: 3px dashed $tr-light-gray;
  margin-top: 40px;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  justify-content: space-evenly;
}

.big-num {
  font-size: 50px;
}

.glasses-green {
  position: relative;
  left: 0;
  top: 5px;
}

.number-column {
  width: 100%;
}

.grey-label {
  color: darkgrey;
}

.range-option {
  color: $tr-black;
  padding: 5px 10px;
  cursor: pointer;
  margin-bottom: 1px;

  &:hover {
    outline: $tr-black 1px solid;
  }
}

.highlighted {
  background-color: $tr-black;
  color: $tr-white;
}

</style>

<style>
  .highcharts-root, .highcharts-container  {
    overflow: visible !important;
  }
</style>